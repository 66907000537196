import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Torneos = () => {
  const [tournaments, setTournaments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchEvents = async () => {
      const tournamentFiles = ["christmas-koben-cup.md"];

      try {
        const tournamentData = await Promise.all(
          tournamentFiles.map((file) =>
            fetch(`/Torneos/${file}`).then((res) => {
              if (!res.ok) {
                throw new Error(`No se pudo cargar el archivo ${file}`);
              }
              return res.text();
            })
          )
        );

        const tournamentMeta = tournamentData.map((data) => {
          const metadataEndIndex = data.indexOf("---", 3);
          const metadata = data.substring(0, metadataEndIndex + 3);
          const content = data.substring(metadataEndIndex + 3).trim();

          const metadataObject = metadata.split("\n").reduce((acc, line) => {
            const [key, value] = line.split(": ").map((str) => str.trim());
            if (key && value) {
              acc[key] = value;
            }
            return acc;
          }, {});

          return {
            ...metadataObject,
            content,
          };
        });

        setTournaments(tournamentMeta);
      } catch (error) {
        console.error("Error al cargar los tournamentos:", error);
      } finally {
        setTimeout(() => {
          setIsLoading(false);
        }, 300);
      }
    };

    fetchEvents();
  }, []);

  return (
    <div className="p-4 md:p-0 max-w-[1200px] m-auto w-full mt-24 md:mt-40 flex flex-col gap-y-10">
      <div>
        <h1 className="text-5xl font-bold">Torneos</h1>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-1 gap-10">
        {tournaments.map((tournament, index) => (
          <Link to={`/torneos/${tournament.title}`} key={index}>
            <div className="w-full cursor-pointer flex flex-col gap-y-2 md:flex-row justify-between px-6 py-3 bg-black bg-opacity-90 backdrop-blur-sm rounded-lg border border-zinc-700 hover:bg-zinc-900">
              <div className="flex gap-4 items-center">
                <img
                  className="w-[80px]"
                  src="/assets/logokoben.png"
                  alt="Logo Koben"
                />

                <div className="flex flex-col gap-y-1">
                  <h2 className="text-xl">{tournament.title}</h2>{" "}
                  <p className="text-zinc-500">
                    {tournament.location} ({tournament.mode}) {tournament.date}
                  </p>
                </div>
              </div>
              <div className="flex gap-10 items-center">
                <div className="flex flex-col items-center">
                  <p>{tournament.teams}</p>
                  <p>Equipos</p>
                </div>
                <div className="flex flex-col items-center">
                  <p>{tournament.prize}</p>
                  <p>Premio</p>
                </div>
                <div className="flex flex-col items-center">
                  <p>{tournament.mode}</p>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Torneos;
