import React, { useState } from "react";
import { Link } from "react-router-dom";

const TeamsForm = () => {
  const [formData, setFormData] = useState({
    teamName: "",
    img: null,
    email: "",
    player1: "",
    player2: "",
    player3: "",
    player4: "",
    player5: "",
    player6: " ",
    player7: " ",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    const { name, value, files, type, checked } = e.target;

    if (type === "checkbox") {
      setFormData({ ...formData, [name]: checked });
    } else if (name === "teamLogo") {
      setFormData({ ...formData, img: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const validateForm = () => {
    if (!formData.teamName) return "El nombre del equipo es obligatorio.";
    if (!formData.img) return "El logo del equipo es obligatorio.";
    if (!formData.email) return "El correo es obligatorio.";
    if (!formData.player1) return "El jugador 1 es obligatorio.";
    if (!formData.player2) return "El jugador 2 es obligatorio.";
    if (!formData.player3) return "El jugador 3 es obligatorio.";
    if (!formData.player4) return "El jugador 4 es obligatorio.";
    if (!formData.player5) return "El jugador 5 es obligatorio.";
    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");

    const error = validateForm();
    if (error) {
      setErrorMessage(error);
      return;
    }

    setIsLoading(true);

    const formToSubmit = new FormData();
    formToSubmit.append("teamName", formData.teamName);
    formToSubmit.append("img", formData.img);
    formToSubmit.append("email", formData.email);
    formToSubmit.append("player1", formData.player1);
    formToSubmit.append("player2", formData.player2);
    formToSubmit.append("player3", formData.player3);
    formToSubmit.append("player4", formData.player4);
    formToSubmit.append("player5", formData.player5);
    if (formData.player6) formToSubmit.append("player6", formData.player6);
    if (formData.player7) formToSubmit.append("player7", formData.player7);

    try {
      const response = await fetch(
        "https://team-koben-backend.vercel.app/teams/create",
        /* "http://localhost:8080/teams/create", */
        {
          method: "POST",
          body: formToSubmit,
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Error desconocido");
      }

      const result = await response.json();
      alert("Equipo creado exitosamente!");
      console.log(result);
    } catch (error) {
      console.error("Error al enviar el formulario:", error);
      setErrorMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="text-white">
      <form className="flex flex-col gap-y-8" onSubmit={handleSubmit}>
        <div className="flex flex-col gap-y-4">
          <label>
            Team Name <span className="text-red-600">*</span>
          </label>
          <input
            className="border border-zinc-600 rounded-md px-2 py-2 bg-transparent"
            type="text"
            name="teamName"
            value={formData.teamName}
            onChange={handleChange}
          />
        </div>

        <div className="flex flex-col gap-y-2">
          <label>
            Team Logo <span className="text-red-600">*</span>
          </label>
          <input
            className="border border-zinc-600 rounded-md px-2 py-2 bg-transparent"
            type="file"
            name="teamLogo"
            accept=".png, .jpg, .jpeg"
            onChange={handleChange}
          />
        </div>

        <div className="flex flex-col gap-y-2">
          <label>
            Correo de contacto del capitán/representante del equipo{" "}
            <span className="text-red-600">*</span>
          </label>
          <input
            className="border border-zinc-600 rounded-md px-2 py-2 bg-transparent"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>

        <div className="flex flex-col gap-y-4">
          <p>
            El <span className="font-bold">jugador 1 </span> sera el{" "}
            <span className="font-bold">capitán/representante</span> del equipo
          </p>
          <p>
            Deberas ingresar los{" "}
            <span className="font-bold">RIOT ID (player#0000)</span> de cada
            jugador.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
          {Array.from({ length: 7 }, (_, i) => (
            <div key={i} className="flex flex-col gap-y-2">
              <label className="flex gap-x-1">
                {`Jugador ${i + 1}`}
                {i < 5 && <span className="text-red-600">*</span>}
              </label>
              <input
                className="border border-zinc-600 rounded-md px-2 py-2 bg-transparent"
                type="text"
                name={`player${i + 1}`}
                value={formData[`player${i + 1}`]}
                onChange={handleChange}
                required={i < 5}
              />
            </div>
          ))}
        </div>

        <div>
          <p>
            Al hacer click en Crear equipo aceptas los{" "}
            <Link
              className="uppercase hover:underline text-blue-600"
              to="/terminos-y-condiciones"
            >
              Términos y Condiciones
            </Link>
          </p>
        </div>

        {errorMessage && (
          <div className="text-red-500 font-bold text-center">
            {errorMessage}
          </div>
        )}

        <div className="flex justify-center">
          <button
            className="text-white w-fit bg-[#061f7e] hover:bg-[#419CF6] text-xl px-3 py-1 rounded-lg"
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? "Creando equipo..." : "Crear equipo"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default TeamsForm;
