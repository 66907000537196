import React from "react";

const Terms = () => {
  return (
    <div className="p-4 md:p-0 max-w-[1200px] m-auto mt-24 md:mt-40 flex flex-col gap-y-10">
      <div>
        <h1 className="text-5xl font-bold">Términos y Condiciones</h1>
      </div>
      <div className="text-base bg-white p-6 md:p-10 text-black rounded-lg">
        <h2 className="text-2xl font-semibold mt-6">
          1. Aceptación de los Términos
        </h2>
        <p>
          Al utilizar los servicios de <strong>Team Koben</strong>, el usuario
          acepta cumplir con los Términos y Condiciones establecidos en este
          documento. Estos Términos pueden ser modificados en cualquier momento,
          por lo que es responsabilidad del usuario revisarlos periódicamente.
          El uso continuado del Sitio Web después de cualquier cambio
          constituirá la aceptación de dichos cambios.
        </p>

        <h2 className="text-2xl font-semibold mt-6">2. Servicios Ofrecidos</h2>
        <p>
          <strong>Team Koben</strong> ofrece una plataforma en línea donde los
          usuarios pueden:
        </p>
        <ul className="list-disc ml-6">
          <li>Ver información sobre eventos y torneos de videojuegos.</li>
          <li>
            Inscribir equipos para participar en eventos y torneos sin necesidad
            de crear una cuenta en el sitio.
          </li>
          <li>Acceder a reglas, horarios y detalles de cada evento.</li>
          <li>Realizar pagos de inscripción, si aplica.</li>
        </ul>

        <h2 className="text-2xl font-semibold mt-6">
          3. Inscripción a Torneos y Eventos
        </h2>
        <p>
          Los usuarios pueden inscribir sus equipos a los torneos y eventos
          organizados en <strong>Team Koben</strong> sin necesidad de
          registrarse en el sitio. Para inscribirse, los usuarios deberán
          proporcionar la siguiente información:
        </p>
        <ul className="list-disc ml-6">
          <li>Nombre del equipo.</li>
          <li>
            Jugadores que conforman el equipo (pueden ser jugadores invitados o
            registrados).
          </li>
          <li>
            Información de contacto (por ejemplo, correo electrónico o
            teléfono).
          </li>
          <li>
            Pago de inscripción (si corresponde), según los detalles del evento.
          </li>
        </ul>
        <p>
          Cada evento o torneo tiene reglas, fechas y tarifas de inscripción
          específicas. Es responsabilidad del usuario leer y aceptar estas
          reglas antes de inscribirse en un evento.
        </p>

        <h2 className="text-2xl font-semibold mt-6">4. Pagos y Tarifas</h2>
        <p>
          En algunos casos, los torneos y eventos pueden requerir el pago de una
          tarifa de inscripción. El pago debe realizarse a través de los métodos
          habilitados en la plataforma. <strong>Team Koben</strong> no
          reembolsará los pagos salvo en circunstancias excepcionales, que serán
          evaluadas de manera individual.
        </p>

        <h2 className="text-2xl font-semibold mt-6">
          5. Reglas y Condiciones de los Torneos
        </h2>
        <p>
          Cada torneo o evento organizado por <strong>Team Koben</strong> puede
          tener reglas propias. Es responsabilidad de los participantes leer,
          comprender y cumplir con estas reglas antes de inscribirse en un
          evento.
        </p>
        <p>
          El incumplimiento de las reglas puede resultar en la descalificación
          del equipo o jugador sin derecho a reembolso.
        </p>

        <h2 className="text-2xl font-semibold mt-6">
          6. Comportamiento de los Usuarios
        </h2>
        <p>El usuario se compromete a:</p>
        <ul className="list-disc ml-6">
          <li>
            No realizar actividades fraudulentas ni interferir con el
            funcionamiento del Sitio Web.
          </li>
          <li>
            No utilizar el Sitio Web para publicar o compartir contenido
            inapropiado, ofensivo o ilegal.
          </li>
          <li>
            Tratar con respeto a otros usuarios y organizadores de eventos y
            torneos.
          </li>
        </ul>
        <p>
          <strong>Team Koben</strong> se reserva el derecho de suspender la
          participación de cualquier equipo o usuario que viole estas normas.
        </p>

        <h2 className="text-2xl font-semibold mt-6">
          7. Propiedad Intelectual
        </h2>
        <p>
          Todos los contenidos del Sitio Web, incluidos textos, gráficos,
          logotipos, imágenes, software y demás materiales, son propiedad de{" "}
          <strong>Team Koben</strong> o de terceros que han otorgado licencias a
          la empresa. El uso de estos materiales sin el consentimiento previo de{" "}
          <strong>Team Koben</strong> está prohibido.
        </p>

        <h2 className="text-2xl font-semibold mt-6">
          8. Limitación de Responsabilidad
        </h2>
        <p>
          <strong>Team Koben</strong> no se hace responsable de los daños o
          perjuicios derivados de:
        </p>
        <ul className="list-disc ml-6">
          <li>Errores en el Sitio Web.</li>
          <li>Interrupciones en el servicio debido a fallos técnicos.</li>
          <li>
            Problemas de conexión a internet o de pago que impidan la
            inscripción de un equipo.
          </li>
          <li>
            Cualquier evento fuera del control razonable de{" "}
            <strong>Team Koben</strong>.
          </li>
        </ul>
        <p>
          <strong>Team Koben</strong> no será responsable por la conducta de
          otros usuarios de la plataforma ni por los daños resultantes de su
          participación en los torneos.
        </p>

        <h2 className="text-2xl font-semibold mt-6">
          9. Modificaciones al Servicio
        </h2>
        <p>
          <strong>Team Koben</strong> se reserva el derecho de modificar,
          suspender o discontinuar cualquiera de los servicios ofrecidos en el
          Sitio Web, en cualquier momento y sin previo aviso. No se garantiza
          que los eventos o torneos disponibles en la plataforma estén siempre
          activos o disponibles.
        </p>

        <h2 className="text-2xl font-semibold mt-6">10. Privacidad</h2>
        <p>
          El uso del Sitio Web está sujeto a nuestra{" "}
          <strong>Política de Privacidad</strong>, que se detalla en [enlace a
          la política de privacidad]. Al utilizar nuestros servicios, aceptas la
          recopilación y el uso de tu información conforme a dicha política.
        </p>

        <h2 className="text-2xl font-semibold mt-6">
          11. Terminación de Participación
        </h2>
        <p>
          <strong>Team Koben</strong> se reserva el derecho de suspender la
          participación de cualquier equipo o usuario que no cumpla con estos
          Términos y Condiciones. La suspensión de la participación de un equipo
          puede ocurrir en cualquier momento y por cualquier motivo, sin previo
          aviso.
        </p>

        <h2 className="text-2xl font-semibold mt-6">
          12. Ley Aplicable y Jurisdicción
        </h2>
        <p>
          Estos Términos se regirán e interpretarán de acuerdo con las leyes
          Españolas. Cualquier disputa que surja en relación con estos Términos
          será resuelta por los tribunales competentes de España.
        </p>
      </div>
    </div>
  );
};

export default Terms;
