import React from "react";
import Youtube from "../icons/Youtube";
import { Link } from "react-router-dom";

const Home = () => {
  const videos = [
    {
      link: "https://www.youtube.com/embed/2Q9CWzM9nLA?si=vk4BIgJzhZnLc23Y",
      title: "SI SE PUEDE / CSGO KOBEN EDIT",
    },
    {
      link: " https://www.youtube.com/embed/hVX2TDfF2CA?si=J1oR4Y95SclDgaoS",
      title: "TEAM KOBEN RAP BY PLAF (LYRIC)",
    },
  ];

  return (
    <div className="mt-20 md:mt-0 flex flex-col gap-20">
      <Link to="/eventos/Christmas-Koben-Cup" className="text-white">
        <div className="cursor-pointer">
          <img
            className="hidden md:flex w-full"
            src="/assets/christmasValo.webp"
            alt="christmasValo"
          />
          <img
            className="w-full md:hidden"
            src="/assets/ChristmasValoMobile.webp"
            alt="christmasValo"
          />
        </div>
      </Link>
      <div className="p-4 md:p-2 max-w-[1200px] m-auto flex flex-col gap-20 text-white">
        <div className="flex flex-col gap-10">
          <h1 className="text-4xl font-bold text-center uppercase">
            Team Koben
          </h1>
          <p className="text-xl">
            Team Koben es más que un equipo, es un grupo de amigos, es una
            comunidad, es ese rincón al que acudes cuando quieres despejarte,
            competir o simplemente conectar con otros jugadores. Nace en 2019,
            de la mano de Javi Azagra (Tkvil) e Ignacio Visiers (Visiers) y en
            sus inicios se centraban en juegos como CSGO y Rocket League.
          </p>
          <p className="text-xl">
            Todo tiene un porqué en este equipo y el nombre no iba a ser menos,
            pues al ser los dos fundadores de Alcobendas (Madrid), debían buscar
            un nombre que tuviese un vínculo mucho más allá de cualquier juego,
            torneo o experiencia, así que de esta manera nace Koben, que viene
            de Al - coben - das, aunque con C quedaba un poco feo por lo cual el
            nombre final fue Koben o Team Koben para darle un poco más de
            empaque. El camino de Koben no ha sido un camino muy consistente en
            el tiempo y ha cambiado de innumerables formas con el transcurso de
            los años, sin embargo la esencia se ha mantenido intacta hasta el
            día de hoy.
          </p>
          <p className="text-xl">
            ¿Quieres ser parte de Koben? ¿Por qué debería entrar? Es probable
            que un equipo nuevo al que acabes de conocer no te sea de gran
            emoción, pero no nos cabe ni la menor duda de que aquí encontrarás
            tu sitio y conocerás un sinfín de personas con las que seguro que
            entablas bonitas amistades y con las cuales llevas tu juego al
            siguiente nivel. ¡Te esperamos en nuestro discord y en el campo de
            batalla!
          </p>
        </div>

        <div className="flex flex-col gap-10">
          <h1 className="text-4xl font-bold text-center uppercase">
            Nosotros te brindaremos{" "}
            <span className="text-blue-600">entretenimiento</span>
          </h1>
          <div className="flex flex-col gap-6">
            <div className="flex flex-col md:flex-row gap-3 items-center justify-between">
              <p>Visita nuestros ultimos videos</p>
              <a
                className="flex gap-3 items-center bg-red-500 px-3 py-1 rounded-lg hover:bg-white hover:text-black uppercase"
                href="https://www.youtube.com/@teamkobenesp"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Youtube /> tkb en youtube
              </a>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {videos.map((video, index) => (
                <iframe
                  key={index}
                  className="rounded-lg w-full h-[200px] md:h-[330px]"
                  src={video.link}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
