import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "../styles/style.css";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const links = [
    {
      href: "/",
      text: "Inicio",
    },
    {
      href: "/eventos",
      text: "Eventos",
    },
    {
      href: "/torneos",
      text: "Torneos",
    },
    {
      href: "/contacto",
      text: "Contáctanos",
    },
  ];

  const getRandomColor = () => {
    const colors = ["red", "green", "yellow", "blue", "purple"];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  return (
    <div className="text-white flex bg-black bg-opacity-80 px-6 md:px-20 py-2 justify-between md:justify-around items-center backdrop-blur-xl border-b border-white fixed w-full z-[99999]">
      <nav className="flex items-center w-full m-auto">
        <div className="flex gap-10 justify-between w-full">
          <div>
            <NavLink to="/">
              <img
                className="w-[40px] md:w-[45px] h-auto"
                src="/assets/logokoben.png"
                alt="Team Koben"
              />
            </NavLink>
          </div>
          <div className="hidden md:flex items-center gap-10 text-base">
            {links.map((link, index) => (
              <NavLink
                className="hover:underline hover:text-blue-300"
                key={index}
                to={link.href}
              >
                {link.text}
              </NavLink>
            ))}
          </div>
        </div>
      </nav>

      <div className="flex md:hidden items-center gap-2 text-lg">
        {!menuOpen ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M4 6l16 0" />
            <path d="M4 12l16 0" />
            <path d="M4 18l16 0" />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M6 18L18 6M6 6l12 12" />
          </svg>
        )}
      </div>

      {menuOpen && (
        <div className="md:hidden flex flex-col items-center bg-black text-white py-4 absolute top-[69px] left-0 right-0 z-[99998]">
          {links.map((link, index) => (
            <NavLink
              className="hover:underline hover:text-blue-300 py-2"
              key={index}
              to={link.href}
              onClick={() => setMenuOpen(false)}
            >
              {link.text}
            </NavLink>
          ))}
        </div>
      )}
    </div>
  );
};

export default Header;
