import React from "react";

const Privacy = () => {
  return (
    <div className="p-4 md:p-0 max-w-[1200px] m-auto mt-24 md:mt-40 flex flex-col gap-y-10">
      <div>
        <h1 className="text-5xl font-bold">Política de Privacidad</h1>
      </div>
      <div className="text-base bg-white p-6 md:p-10 text-black rounded-lg">
        <h2 className="text-2xl font-semibold mt-6">
          1. Información que Recopilamos
        </h2>
        <p>
          Recopilamos la siguiente información cuando los usuarios interactúan
          con el Sitio Web:
        </p>

        <h3 className="text-xl font-semibold mt-4">1.1 Información Personal</h3>
        <p>
          Cuando los usuarios se inscriben en un torneo o evento, recopilamos
          información personal que puede incluir:
        </p>
        <ul className="list-disc ml-6">
          <li>Nombre del equipo.</li>
          <li>Nombre completo de los jugadores del equipo.</li>
          <li>
            Dirección de correo electrónico de los jugadores o del equipo.
          </li>
          <li>
            Información de contacto, como número de teléfono (si es necesario).
          </li>
          <li>
            Datos de pago, si corresponde (por ejemplo, para procesar tarifas de
            inscripción).
          </li>
        </ul>

        <h3 className="text-xl font-semibold mt-4">1.2 Información Técnica</h3>
        <p>
          Cuando los usuarios visitan nuestro Sitio Web, recopilamos información
          técnica automáticamente, que puede incluir:
        </p>
        <ul className="list-disc ml-6">
          <li>Dirección IP.</li>
          <li>Tipo de navegador y sistema operativo.</li>
          <li>
            Información sobre la actividad de navegación, como las páginas
            visitadas, la duración de la visita y la interacción con el
            contenido.
          </li>
        </ul>

        <h3 className="text-xl font-semibold mt-4">1.3 Cookies</h3>
        <p>
          Utilizamos cookies y tecnologías similares para mejorar la experiencia
          del usuario en nuestro Sitio Web, analizar el tráfico y personalizar
          el contenido. Las cookies son pequeños archivos de texto que se
          almacenan en el dispositivo del usuario.
        </p>

        <h2 className="text-2xl font-semibold mt-6">
          2. Uso de la Información Recopilada
        </h2>
        <p>
          Utilizamos la información que recopilamos para los siguientes fines:
        </p>
        <ul className="list-disc ml-6">
          <li>
            Gestionar la inscripción a eventos y torneos: Procesar la
            información de los equipos y jugadores inscritos, incluyendo el
            envío de confirmaciones y recordatorios sobre eventos.
          </li>
          <li>
            Mejorar la experiencia del usuario: Personalizar el contenido y
            mejorar la funcionalidad del Sitio Web.
          </li>
          <li>
            Comunicación con los usuarios: Responder a consultas, enviar
            actualizaciones sobre eventos, cambios en las reglas o cualquier
            otra información relacionada.
          </li>
          <li>
            Procesar pagos: Gestionar las tarifas de inscripción para torneos y
            eventos, si corresponde.
          </li>
          <li>
            Análisis y estadísticas: Recopilar información sobre el uso del
            Sitio Web para mejorar nuestros servicios y contenido.
          </li>
        </ul>

        <h2 className="text-2xl font-semibold mt-6">
          3. Compartir la Información
        </h2>
        <p>
          Nos comprometemos a no vender, alquilar ni compartir la información
          personal de nuestros usuarios con terceros, excepto en las siguientes
          situaciones:
        </p>
        <ul className="list-disc ml-6">
          <li>
            Proveedores de servicios: Podemos compartir información con
            proveedores de servicios de confianza que nos ayudan a operar el
            Sitio Web, procesar pagos y gestionar eventos. Estos proveedores
            están obligados a mantener la confidencialidad de la información y
            solo pueden utilizarla para los fines específicos para los cuales
            les ha sido proporcionada.
          </li>
          <li>
            Cumplimiento legal: Podemos divulgar información personal si es
            necesario para cumplir con una obligación legal, proteger nuestros
            derechos, o en caso de una solicitud válida de autoridades
            competentes.
          </li>
        </ul>

        <h2 className="text-2xl font-semibold mt-6">
          4. Seguridad de la Información
        </h2>
        <p>
          Tomamos medidas razonables para proteger la información personal de
          nuestros usuarios, incluyendo el uso de cifrado y otras prácticas de
          seguridad. Sin embargo, ningún sistema de transmisión de datos a
          través de Internet o almacenamiento electrónico es 100% seguro, por lo
          que no podemos garantizar la seguridad absoluta de la información.
        </p>

        <h2 className="text-2xl font-semibold mt-6">
          5. Derechos de los Usuarios
        </h2>
        <p>
          De acuerdo con la legislación aplicable, los usuarios tienen ciertos
          derechos sobre sus datos personales, incluyendo:
        </p>
        <ul className="list-disc ml-6">
          <li>
            Acceso: Puedes solicitar una copia de la información personal que
            tenemos sobre ti.
          </li>
          <li>
            Rectificación: Si crees que la información que tenemos es incorrecta
            o incompleta, puedes solicitarnos que la corrijamos.
          </li>
          <li>
            Eliminación: Puedes solicitar la eliminación de tus datos
            personales, aunque en algunos casos puede que necesitemos retener
            cierta información por razones legales o contractuales.
          </li>
          <li>
            Restricción: Puedes pedirnos que limitemos el uso de tus datos
            personales en ciertas circunstancias.
          </li>
          <li>
            Oposición: Puedes oponerte al procesamiento de tus datos personales
            en determinadas situaciones.
          </li>
          <li>
            Portabilidad: En ciertos casos, puedes solicitar que te
            proporcionemos tus datos en un formato estructurado y de uso común
            para que los transfieras a otro servicio.
          </li>
        </ul>
        <p>
          Para ejercer estos derechos, por favor, contáctanos a través de la
          dirección de correo electrónico proporcionada en la sección de
          contacto.
        </p>

        <h2 className="text-2xl font-semibold mt-6">6. Retención de Datos</h2>
        <p>
          Conservamos la información personal de los usuarios solo durante el
          tiempo necesario para cumplir con los fines establecidos en esta
          Política de Privacidad o según lo exijan las leyes aplicables. Una vez
          que ya no sea necesario, eliminamos o anonimizamos la información.
        </p>

        <h2 className="text-2xl font-semibold mt-6">
          7. Enlaces a Sitios Externos
        </h2>
        <p>
          Nuestro Sitio Web puede contener enlaces a otros sitios web que no
          están operados por nosotros. No somos responsables de las prácticas de
          privacidad de esos sitios. Te recomendamos que revises las políticas
          de privacidad de cada sitio web que visites.
        </p>

        <h2 className="text-2xl font-semibold mt-6">
          8. Actualizaciones de la Política de Privacidad
        </h2>
        <p>
          Podemos actualizar esta Política de Privacidad de vez en cuando para
          reflejar cambios en nuestras prácticas de privacidad o para cumplir
          con requisitos legales. Te notificaremos cualquier cambio importante
          mediante una publicación en el Sitio Web y actualizaremos la fecha de
          la última actualización al final de esta página.
        </p>

        <h2 className="text-2xl font-semibold mt-6">9. Contacto</h2>
        <p>
          Si tienes alguna pregunta sobre esta Política de Privacidad o sobre
          cómo manejamos tu información personal, puedes ponerte en contacto con
          nosotros a través de:
        </p>
        <ul className="list-disc ml-6">
          <li>
            <strong>Correo electrónico</strong>: contacto@teamkoben.com
          </li>
        </ul>

        <p className="mt-4">Fecha de la última actualización: 10/12/2024</p>
      </div>
    </div>
  );
};

export default Privacy;
