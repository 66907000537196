import { Route, Routes } from "react-router-dom";
import "./App.css";
import Header from "./components/Header";
import Home from "./pages/Home";
import Error404 from "./components/Error404";
import Footer from "./components/Footer";
import Events from "./pages/Events";
import EventDetails from "./pages/EventDetails";
import Torneos from "./pages/Torneos";
import TorneoDetails from "./pages/TorneoDetails";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import { Analytics } from "@vercel/analytics/react";
import Contact from "./pages/Contact";

function App() {
  return (
    <div className="flex flex-col justify-between h-screen">
      <Analytics />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/eventos" element={<Events />} />
        <Route path="/eventos/:eventTitle" element={<EventDetails />} />
        <Route path="/torneos" element={<Torneos />} />
        <Route path="/torneos/:tournamentTitle" element={<TorneoDetails />} />
        <Route path="/contacto" element={<Contact />} />
        <Route path="/terminos-y-condiciones" element={<Terms />} />
        <Route path="/politica-de-privacidad" element={<Privacy />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
