import React, { useState } from "react";
import Instagram from "../icons/Instagram";
import Twitter from "../icons/Twitter";
import Youtube from "../icons/Youtube";
import Discord from "../icons/Discord";
import Twitch from "../icons/Twitch";

const Contact = () => {
  const [openFAQ, setOpenFAQ] = useState(null);

  const socials = [
    { icon: <Twitter />, href: "https://x.com/teamkoben", label: "Twitter" },
    {
      icon: <Instagram />,
      href: "https://www.instagram.com/kobenesports",
      label: "Instagram",
    },
    {
      icon: <Youtube />,
      href: "https://www.youtube.com/channel/UC5l1BxT-k6sRnqCfEyFJOuw",
      label: "YouTube",
    },
    {
      icon: <Discord />,
      href: "https://discord.gg/jVkCpmG4bh",
      label: "Discord",
    },
    {
      icon: <Twitch />,
      href: "https://www.twitch.tv/teamkoben",
      label: "Twitch",
    },
  ];

  const faqs = [
    {
      question: "¿Cómo puedo inscribirme en un torneo?",
      answer:
        "Para inscribirte en nuestros torneos, solo tienes que inscribirte a través de nuestra página web o en nuestro Discord. Asegúrate de leer las reglas antes de inscribirte.",
    },
    {
      question: "¿Qué es Team Koben?",
      answer:
        "Team Koben es una comunidad de esports que organiza torneos y eventos de videojuegos, especialmente de Valorant, para jugadores de todos los niveles.",
    },
    {
      question: "¿Cuáles son las reglas para participar en un torneo?",
      answer:
        "Cada torneo tiene sus propias reglas específicas, pero en general, esperamos que los participantes sigan un código de conducta respetuoso y jueguen de manera justa. Puedes consultar las reglas en la página del torneo correspondiente.",
    },
    {
      question: "¿Puedo patrocinar o apoyar a Team Koben?",
      answer:
        "¡Sí! Si estás interesado en patrocinar o apoyar a Team Koben, por favor contáctanos por correo electrónico a contacto@teamkoben.com. Estamos siempre abiertos a nuevas oportunidades de colaboración.",
    },
  ];

  const toggleFAQ = (index) => {
    setOpenFAQ(openFAQ === index ? null : index);
  };

  return (
    <div className="p-4 md:p-0 max-w-[1200px] m-auto w-full mt-24 md:mt-40 flex flex-col gap-y-10">
      <div>
        <h1 className="text-5xl font-bold">Contacto</h1>
      </div>

      <div className="flex flex-col gap-y-6">
        <h2 className="text-2xl font-semibold ">
          ¿Necesitas ayuda o quieres contactarnos?
        </h2>
        <p className="text-lg ">
          Puedes contactarnos a través de nuestro correo electrónico:{" "}
          <a href="mailto:contacto@teamkoben.com" className="text-blue-400">
            contacto@teamkoben.com
          </a>
        </p>
        <div className="flex flex-col gap-y-4">
          <p className="text-lg">
            También puedes contactarnos a través de nuestras redes sociales:
          </p>
          <div className="flex justify-center md:justify-start gap-8">
            {socials.map((social, index) => (
              <div key={index} className="text-white text-3xl">
                <a
                  href={social.href}
                  target="_blank"
                  rel="noreferrer"
                  aria-label={`Ir a nuestra página de ${social.label}`}
                  className="hover:text-blue-300 transition duration-300 transform hover:scale-110"
                >
                  {social.icon}
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="mt-12">
        <h2 className="text-2xl font-semibold">Preguntas Frecuentes</h2>
        <div className="flex flex-col gap-y-4 mt-6">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className="text-black border-b-2 border-gray-300  bg-white/70 px-4 py-4 rounded-lg"
            >
              <button
                onClick={() => toggleFAQ(index)}
                className="w-full flex justify-between items-center"
              >
                <h2 className="text-xl font-semibold w-full text-left">
                  {faq.question}
                </h2>
                {openFAQ === index ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-up"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M6 15l6 -6l6 6" />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-down"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M6 9l6 6l6 -6" />
                  </svg>
                )}
              </button>

              {openFAQ === index && (
                <p className="mt-2 text-base text-gray-900">{faq.answer}</p>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Contact;
