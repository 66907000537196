import React from "react";
import { Link } from "react-router-dom";

const Error404 = () => {
  return (
    <div className="h-screen text-white flex flex-col justify-center items-center mt-56">
      <h1 className="font-bold text-9xl">404</h1>
      <h2 className="font-bold text-7xl">Not Found</h2>
      <Link to="/" className="w-fit hover:underline text-2xl">
        Volver al inicio
      </Link>
    </div>
  );
};

export default Error404;
