import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const TorneoDetails = () => {
  const { tournamentTitle } = useParams();
  const [tournament, setTournament] = useState(null);

  useEffect(() => {
    const fetchTournamentDetail = async () => {
      const tournamentFile = `${tournamentTitle
        .toLowerCase()
        .replace(/ /g, "-")}.md`;

      try {
        const res = await fetch(`/Torneos/${tournamentFile}`);
        if (!res.ok) {
          throw new Error("No se pudo cargar el archivo del evento");
        }
        const data = await res.text();

        const metadataEndIndex = data.indexOf("---", 3);
        const metadata = data.substring(0, metadataEndIndex + 3);
        const content = data.substring(metadataEndIndex + 3).trim();

        const metadataObject = metadata.split("\n").reduce((acc, line) => {
          const [key, value] = line.split(": ").map((str) => str.trim());
          if (key && value) {
            acc[key] = value;
          }
          return acc;
        }, {});

        setTournament({ ...metadataObject, content });
      } catch (error) {
        console.error("Error al cargar el evento:", error);
      }
    };

    fetchTournamentDetail();
  }, [tournamentTitle]);

  if (!tournament) {
    return <div>Cargando evento...</div>;
  }

  const teams = [
    {
      name: "TBD",
      logo: "/assets/tbd.png",
    },
    {
      name: "TBD",
      logo: "/assets/tbd.png",
    },
    {
      name: "TBD",
      logo: "/assets/tbd.png",
    },
    {
      name: "TBD",
      logo: "/assets/tbd.png",
    },
    {
      name: "TBD",
      logo: "/assets/tbd.png",
    },
    {
      name: "TBD",
      logo: "/assets/tbd.png",
    },
    {
      name: "TBD",
      logo: "/assets/tbd.png",
    },
    {
      name: "TBD",
      logo: "/assets/tbd.png",
    },
  ];

  return (
    <div className="p-4 md:p-4 max-w-[1200px] m-auto mt-32 md:mt-40 flex flex-col gap-y-10 md:gap-y-10">
      <div>
        <div className="relative">
          <img
            className="w-full rounded-t-lg"
            src={tournament.img2}
            alt={tournament.title2}
          />

          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col items-center">
            <img
              className="w-[100px]"
              src={tournament.img}
              alt={tournament.title2}
            />
            <h1 className="hidden md:flex text-4xl font-bold text-white">
              {tournament.title2}
            </h1>
          </div>
        </div>
        <div className="bg-[#CCCCCC] rounded-b-lg text-black flex justify-between flex-col gap-y-4 md:flex-row px-6 py-3">
          <div>
            <p className="text-[#34495E] text-sm">Fecha</p>
            <p className="font-bold">{tournament.date}</p>
          </div>
          <div>
            <p className="text-[#34495E] text-sm">Premio</p>
            <p className="font-bold">{tournament.prize}</p>
          </div>
          <div>
            <p className="text-[#34495E] text-sm">Equipos</p>
            <p className="font-bold">{tournament.teams}</p>
          </div>
          <div>
            <p className="text-[#34495E] text-sm">Modalidad</p>
            <p className="font-bold">{tournament.mode}</p>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-y-4">
        <div>
          <h2 className="text-3xl font-bold">Bracket</h2>
        </div>
        <div className="bg-[#CCCCCC] rounded-lg text-black flex justify-start flex-wrap gap-x-32 gap-y-10 px-10 py-6 ">
          <div className="flex flex-col gap-y-4 w-[150px]">
            <h3 className="text-lg font-bold">Cuartos de final</h3>
            <div className="flex flex-col gap-y-10">
              <div>
                <div className="bg-zinc-500 border border-zinc-500 rounded-t-lg p-1 text-white flex justify-between text-xs">
                  <p>17:00 20 Dic </p>
                  <p>BO1</p>
                </div>
                <p className="px-3 py-2 bg-[#F6F6F9] border border-black ">
                  TBD
                </p>
                <p className="px-3 py-2 bg-[#F6F6F9] border border-black ">
                  TBD
                </p>
              </div>
              <div>
                <div className="bg-zinc-500 border border-zinc-500 rounded-t-lg p-1 text-white flex justify-between text-xs">
                  <p>18:00 20 Dic </p>
                  <p>BO1</p>
                </div>
                <p className="px-3 py-2 bg-[#F6F6F9] border border-black ">
                  TBD
                </p>
                <p className="px-3 py-2 bg-[#F6F6F9] border border-black ">
                  TBD
                </p>
              </div>

              <div>
                <div className="bg-zinc-500 border border-zinc-500 rounded-t-lg p-1 text-white flex justify-between text-xs">
                  <p>19:00 20 Dic </p>
                  <p>BO1</p>
                </div>
                <p className="px-3 py-2 bg-[#F6F6F9] border border-black ">
                  TBD
                </p>
                <p className="px-3 py-2 bg-[#F6F6F9] border border-black ">
                  TBD
                </p>
              </div>

              <div>
                <div className="bg-zinc-500 border border-zinc-500 rounded-t-lg p-1 text-white flex justify-between text-xs">
                  <p>20:00 20 Dic </p>
                  <p>BO1</p>
                </div>
                <p className="px-3 py-2 bg-[#F6F6F9] border border-black ">
                  TBD
                </p>
                <p className="px-3 py-2 bg-[#F6F6F9] border border-black ">
                  TBD
                </p>
              </div>
            </div>
          </div>

          <div className="w-[150px]">
            <h3 className="text-lg font-bold">Semifinales</h3>
            <div className="flex flex-col h-full justify-around gap-y-10">
              <div>
                <div className="bg-zinc-500 border border-zinc-500 rounded-t-lg p-1 text-white flex justify-between text-xs">
                  <p>17:00 20 Dic </p>
                  <p>BO3</p>
                </div>
                <p className="px-3 py-2 bg-[#F6F6F9] border border-black ">
                  TBD
                </p>
                <p className="px-3 py-2 bg-[#F6F6F9] border border-black ">
                  TBD
                </p>
              </div>

              <div>
                <div className="bg-zinc-500 border border-zinc-500 rounded-t-lg p-1 text-white flex justify-between text-xs">
                  <p>20:00 20 Dic </p>
                  <p>BO3</p>
                </div>
                <p className="px-3 py-2 bg-[#F6F6F9] border border-black ">
                  TBD
                </p>
                <p className="px-3 py-2 bg-[#F6F6F9] border border-black ">
                  TBD
                </p>
              </div>
            </div>
          </div>

          <div className="w-[150px]">
            <h3 className="text-lg font-bold">Final</h3>
            <div className="flex flex-col h-full justify-center gap-y-10">
              <div>
                <div className="bg-zinc-500 border border-zinc-500 rounded-t-lg p-1 text-white flex justify-between text-xs">
                  <p>18:00 20 Dic </p>
                  <p>BO3</p>
                </div>
                <p className="px-3 py-2 bg-[#F6F6F9] border border-black ">
                  TBD
                </p>
                <p className="px-3 py-2 bg-[#F6F6F9] border border-black ">
                  TBD
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-y-5">
        <div>
          <h2 className="text-3xl font-bold">Equipos</h2>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          {teams.map((team, index) => (
            <div
              key={index}
              className="flex flex-col gap-y-5 items-center px-4 py-6 bg-[#CCCCCC] rounded-lg"
            >
              <p className="text-black font-bold text-xl">{team.name}</p>
              <img className="w-[200px]" src={team.logo} alt={team.name} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TorneoDetails;
