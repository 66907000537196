import React from "react";
import Instagram from "../icons/Instagram";
import Twitter from "../icons/Twitter";
import Youtube from "../icons/Youtube";
import Discord from "../icons/Discord";
import Twitch from "../icons/Twitch";
import { NavLink } from "react-router-dom";

const Footer = () => {
  const goTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const socials = [
    {
      icon: <Twitter />,
      href: "https://x.com/teamkoben",
    },
    {
      icon: <Instagram />,
      href: "https://www.instagram.com/kobenesports",
    },
    {
      icon: <Youtube />,
      href: "https://www.youtube.com/channel/UC5l1BxT-k6sRnqCfEyFJOuw",
    },
    {
      icon: <Discord />,
      href: "https://discord.gg/jVkCpmG4bh",
    },
    {
      icon: <Twitch />,
      href: "https://www.twitch.tv/teamkoben",
    },
  ];

  const links = [
    {
      href: "/",
      text: "Inicio",
    },
    {
      href: "/eventos",
      text: "Eventos",
    },
    {
      href: "/torneos",
      text: "Torneos",
    },
    {
      href: "/contacto",
      text: "Contáctanos",
    },
  ];

  return (
    <div className="bg-black px-10 py-6 mt-20">
      <div className="max-w-[1200px] m-auto flex flex-col md:flex-row justify-start gap-x-32 gap-y-10">
        <div className=" flex flex-col gap-y-6">
          <div onClick={goTop}>
            <img
              className="w-[80px] h-auto"
              src="/assets/logokoben.png"
              alt="Team Koben"
            />
          </div>
          <div className="flex gap-6">
            {socials.map((social, index) => (
              <div key={index} className="text-white">
                <a
                  className="hover:text-blue-300"
                  href={social.href}
                  target="_blank"
                  rel="noreferrer"
                >
                  {social.icon}
                </a>
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col flex-wrap md:flex-row gap-x-16 gap-y-10">
          {links.map((link, index) => (
            <div key={index} className="text-white">
              <NavLink
                className="text-xl font-bold hover:underline hover:text-blue-300 "
                to={link.href}
              >
                {link.text}
              </NavLink>
            </div>
          ))}
        </div>
      </div>
      <div className="text-sm text-slate-500 pt-10 max-w-[1200px] m-auto flex flex-col md:flex-row gap-y-6 justify-between items-center">
        <div>
          <p>&copy; 2024 Team Koben. Todos los derechos reservados.</p>
        </div>
        <div className="flex gap-6">
          <NavLink to="/terminos-y-condiciones" className="hover:underline">
            Términos y Condiciones
          </NavLink>
          <NavLink to="/politica-de-privacidad" className="hover:underline">
            Política de privacidad
          </NavLink>
        </div>
      </div>
      <div className="max-w-[1200px] m-auto mt-4">
        <p className="text-sm text-slate-500">
          Desarrollado por{" "}
          <a
            className="hover:underline"
            href="https://javierazagra.com"
            target="_blank"
            rel="noreferrer"
          >
            Javier Azagra
          </a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
