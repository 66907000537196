import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import TeamsForm from "../components/TeamsForm";
import Close from "../icons/Close";

const EventDetail = () => {
  const { eventTitle } = useParams();
  const [event, setEvent] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchEventDetail = async () => {
      const eventFile = `${eventTitle.toLowerCase().replace(/ /g, "-")}.md`;

      try {
        const res = await fetch(`/Eventos/${eventFile}`);
        if (!res.ok) {
          throw new Error("No se pudo cargar el archivo del evento");
        }
        const data = await res.text();

        const metadataEndIndex = data.indexOf("---", 3);
        const metadata = data.substring(0, metadataEndIndex + 3);
        const content = data.substring(metadataEndIndex + 3).trim();

        const metadataObject = metadata.split("\n").reduce((acc, line) => {
          const [key, value] = line.split(": ").map((str) => str.trim());
          if (key && value) {
            acc[key] = value;
          }
          return acc;
        }, {});

        setEvent({ ...metadataObject, content });
      } catch (error) {
        console.error("Error al cargar el evento:", error);
      }
    };

    fetchEventDetail();
  }, [eventTitle]);

  useEffect(() => {
    // Bloquear el scroll del body cuando se abre el modal
    if (showModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    // Restaurar el scroll al cerrar el modal
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showModal]);

  if (!event) {
    return <div>Cargando evento...</div>;
  }

  const getStatusClass = (status) => {
    switch (status) {
      case "Disponibles":
        return "bg-green-400 text-black";
      case "En curso":
        return "bg-yellow-400 text-white";
      case "Terminados":
        return "bg-red-400 text-white";
      default:
        return "bg-blue-400 text-white";
    }
  };

  const getStatusText = (status) => {
    switch (status) {
      case "Disponibles":
        return "Abierto";
      case "En curso":
        return "En Curso";
      case "Terminados":
        return "Terminado";
      default:
        return status;
    }
  };

  // Función para abrir el modal
  const handleOpenModal = () => {
    setShowModal(true);
  };

  // Función para cerrar el modal
  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="max-w-[1200px] m-auto mt-24 md:mt-40 flex flex-col gap-y-1 md:gap-y-10">
      <div className="p-[10px] md:p-0 flex flex-col gap-6">
        <h1 className="text-4xl font-bold text-white">{event.title2}</h1>
        <p
          className={`${getStatusClass(
            event.status
          )} font-bold px-3 py-1 rounded-lg text-black w-fit uppercase`}
        >
          {getStatusText(event.status)}
        </p>
        <img className="rounded-xl mt-5" src={event.img} alt={event.title} />
      </div>
      <ReactMarkdown rehypePlugins={[rehypeRaw]}>{event.content}</ReactMarkdown>

      <div className="flex justify-center">
        <button
          onClick={handleOpenModal}
          className="bg-[#061f7e] hover:bg-[#419CF6] text-xl px-3 py-1 rounded-lg"
        >
          INSCRÍBETE
        </button>
      </div>

      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 top-10 md:top-0 z-50">
          <div className="bg-black bg-opacity-70 backdrop-blur-md px-6 py-6 rounded-lg w-10/12 md:w-2/3 border border-zinc-600 max-h-[80vh] overflow-y-auto">
            <div className="flex justify-end">
              <button
                onClick={handleCloseModal}
                className="bg-red-500 hover:bg-red-400 text-white px-2 py-2 rounded-lg"
              >
                <Close />
              </button>
            </div>
            <TeamsForm />
          </div>
        </div>
      )}
    </div>
  );
};

export default EventDetail;
