import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Events = () => {
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filterStatus, setFilterStatus] = useState("Todos");

  useEffect(() => {
    const fetchEvents = async () => {
      const eventFiles = ["christmas-koben-cup.md"];

      try {
        const eventData = await Promise.all(
          eventFiles.map((file) =>
            fetch(`/Eventos/${file}`).then((res) => {
              if (!res.ok) {
                throw new Error(`No se pudo cargar el archivo ${file}`);
              }
              return res.text();
            })
          )
        );

        const eventMeta = eventData.map((data) => {
          const metadataEndIndex = data.indexOf("---", 3);
          const metadata = data.substring(0, metadataEndIndex + 3);
          const content = data.substring(metadataEndIndex + 3).trim();

          const metadataObject = metadata.split("\n").reduce((acc, line) => {
            const [key, value] = line.split(": ").map((str) => str.trim());
            if (key && value) {
              acc[key] = value;
            }
            return acc;
          }, {});

          return {
            ...metadataObject,
            content,
          };
        });

        setEvents(eventMeta);
      } catch (error) {
        console.error("Error al cargar los eventos:", error);
      } finally {
        setTimeout(() => {
          setIsLoading(false);
        }, 300);
      }
    };

    fetchEvents();
  }, []);

  // Filtrar eventos según el filtro seleccionado
  const filteredEvents =
    filterStatus === "Todos"
      ? events
      : events.filter((event) => event.status === filterStatus);

  const getStatusText = (status) => {
    switch (status) {
      case "Disponibles":
        return "Abierto";
      case "En curso":
        return "En Curso";
      case "Terminados":
        return "Terminado";
      default:
        return status;
    }
  };

  const getStatusClass = (status) => {
    switch (status) {
      case "Disponibles":
        return "bg-green-400 text-black";
      case "En curso":
        return "bg-yellow-400 text-white";
      case "Terminados":
        return "bg-red-400 text-white";
      default:
        return "bg-blue-400 text-white";
    }
  };

  return (
    <div className="p-4 md:p-0 max-w-[1200px] m-auto w-full mt-24 md:mt-40 flex flex-col gap-y-10">
      <div>
        <h1 className="text-5xl font-bold">Eventos</h1>
      </div>

      <div className="flex flex-wrap gap-6">
        {["Todos", "Disponibles", "En curso", "Terminados"].map((status) => (
          <button
            key={status}
            className={`bg-zinc-700 gap-4 px-3 py-1 rounded-lg hover:bg-zinc-300 hover:text-black ${
              filterStatus === status ? "bg-zinc-500 text-white" : ""
            }`}
            onClick={() => setFilterStatus(status)}
          >
            {status}
          </button>
        ))}
      </div>

      <div className="relative">
        {isLoading && (
          <div className="absolute inset-0 flex items-center justify-center bg-opacity-50 z-10">
            <div className="loader border-t-4 border-b-4 border-white rounded-full w-16 h-16 animate-spin"></div>
          </div>
        )}

        <div
          className={`grid grid-cols-1 md:grid-cols-2 gap-10 ${
            isLoading ? "opacity-100" : ""
          }`}
        >
          {filteredEvents.length === 0 ? (
            <div className="w-full md:w-[500px] h-[281.25px] text-xl font-bold text-white">
              No hay eventos.
            </div>
          ) : (
            filteredEvents.map((event, index) => (
              <Link to={`/eventos/${event.title}`} key={index}>
                <div
                  className={`w-full md:w-full md:hover:scale-105 transition-all duration-300 rounded-xl relative ${
                    isLoading ? "opacity-0" : ""
                  }`}
                >
                  <img
                    className={`rounded-xl w-full h-full object-cover ${
                      isLoading ? "opacity-0" : ""
                    }`}
                    src={event.img}
                    alt={event.title}
                  />
                  <div
                    className={`absolute inset-0 bg-opacity-10 rounded-xl`}
                  ></div>
                  <div className="absolute inset-0 flex items-end justify-center z-10 text-white h-full">
                    <h2
                      className={`${getStatusClass(
                        event.status
                      )} mb-3 font-bold px-3 py-1 rounded-lg uppercase`}
                    >
                      {getStatusText(event.status)}
                    </h2>
                  </div>
                </div>
              </Link>
            ))
          )}
        </div>
      </div>

      {/* <div className="text-center">
        <button className="hover:underline">Mostrar más</button>
      </div> */}
    </div>
  );
};

export default Events;
